.paginated-table {
  list-style: none;
  display: flex;
  justify-content: center;
  padding: 0;

  .page {
    padding: 5px 15px;
    cursor: pointer;
    border: 0.8px solid #ececec;
    color: #212121;
    text-decoration: none;
  }

  .page:hover {
    background-color: #d3d3d3;
  }

  .page-active {
    background-color: rgb(49, 128, 207);
    color: white;
  }

  .break {
    padding: 5px;
    cursor: pointer;
    color: #212121;
    text-decoration: none;
  }
}