table.appointment-confirmed {
  background: #abc6e4;
}

table.appointment-notconfirmed {
  background: #9aceb1;
}

table.appointment-accomplished {
  background: #c5ac98;
}

table.appointment-processed {
  background: white;
}

table.workload-absences {
  background-color: rgb(211, 212, 146);
}

table.workload-holidays {
  background-color: #007bff;
}

table.workload-absenceSubmitted {
  background-color: #f0ad4e;
}

table.workload-absenceAccepted {
  background-color: #5cb85c;
}

table.workload-reserved {
  background-color: #54d89a;
}

table.workload-booked {
  background-color: #75affa;
}

div.column-width {
  width: 180px;
}

.column-width {
  width: 180px;
}

.input-form .col {
  margin: 5px;
}

.suggestions {
  width: 65%;
  max-height: 200px;
  position: absolute;
  box-shadow: 3px 3px 5px #999;
  background-color: white;
  padding-top: 10px;
  padding-bottom: 10px;
  z-index: 1000;
  overflow-y: auto;
}

.suggestion {
  text-align: left;
  padding-top: 4px;
  padding-bottom: 4px;
  padding-left: 10px;
  cursor: pointer;
}

.suggestion:hover {
  background-color: #eee;
}

.maximised {
  position: absolute;
  top: 10px;
  left: 10px;
  bottom: 10px;
  right: 10px;
  z-index: 1000;
}

.spinner {
  z-index: 1100;
}

.custom-switch .custom-control-input:checked ~ .custom-control-label::after {
  background-color: white;
}

.custom-switch .custom-control-label::after {
  background-color: white;
}

.custom-control-label::before {
  background-color: var(--primary);
}

.small-card {
  border-color: #75affa;
  border-width: 3px;
}

button.border-grey {
  border-color: #e7e7e7;
}

.disabled-link {
  color: black;
}

.wordWrap-class {
  /* CSS3 */
  white-space: pre-wrap;
  /* Mozilla, since 1999 */
  white-space: -moz-pre-wrap;
  /* Opera 4-6 */
  white-space: -pre-wrap;
  /* Opera 7 */
  white-space: -o-pre-wrap;
  /* Internet Explorer 5.5+ */
  word-break: break-all;
}

.th-smallCard {
  text-align: right;
  width: 40%;
}

strong {
  font-weight: bold;
}

.matrix-overview-table {
  position: relative;
  border-collapse: collapse;
  margin: 25px 0;
  font-size: 0.9em;
  min-width: 600px;
}

.matrix-overview-table thead tr {
  background-color: rgb(49, 128, 207);
  color: white;
  text-align: left;
}
.matrix-overview-table th,
.matrix-overview-table td {
  padding: 12px 15px;
  height: 35px;
  width: 150px;
  text-align: center;
  border: 1px solid white;
}
.matrix-overview-table tbody tr {
  padding: 12px 15px;
  height: 35px;
  text-align: center;
  border: 1px solid white;
  font-weight: bold;
  font-size: 20px;
  background-color: rgb(180, 200, 220);
}

.exclamation-color {
  color: rgb(225, 162, 13);
  font-size: 60px;
}

#activity-buttons {
  margin: 2px;
  height: 60px;
  width: 120px;
}

#to-do-list-filter-buttons {
  padding-left: 10px;
  padding-right: 10px;
  background: white;
}

.disabled-link {
  pointer-events: none;
}

.target-prioritization-label {
  background-color: #3180cf;
  color: white;
  font-size: 15px;
  font-weight: normal;
  text-align: left;
}

.target-prioritization-table {
  position: relative;
  border-collapse: collapse;
  margin: 25px 0;
  font-size: 0.9em;
  min-width: 600px;
}

.target-prioritization-table thead tr {
  background-color: rgb(49, 128, 207);
  color: white;
  text-align: left;
}

.target-prioritization-table th,
.target-prioritization-table td {
  padding: 12px 15px;
  height: 35px;
  width: 150px;
  text-align: center;
  border: 1px solid white;
}

.target-prioritization-table tbody tr {
  padding: 12px 15px;
  height: 35px;
  text-align: center;
  border: 1px solid white;
  font-weight: bold;
  font-size: 20px;
  background-color: rgb(180, 200, 220);
}

.centered-div {
  align-items: center;
  justify-content: center;
  display: flex;
}

.calling-stats-graph {
  height: 400px;
  width: 40%;
  min-width: 400px;
  padding: 20px;
}

.calling-stats-checkbox {
  margin: auto;
}

.overall-prioritization-indicator {
  background-color: #b2cc7b;
  font-size: 20px;
  padding-right: 5px;
  padding-left: 5px;
}

.prioritization-graph-wide {
  height: 200px;
  width: 100%;
  min-width: 200px;
}

.calling-stats-graph-wide {
  height: 400px;
  width: 80%;
  min-width: 400px;
  padding: 20px;
}

.ellipsis-pagination {
  list-style: none;
  display: flex;
  flex-direction: row;
  justify-content: left;
  margin-bottom: 5rem;
  font-size: 1rem;
}

.ellipsis-pagination .page-num {
  padding: 5px 15px;
  cursor: pointer;
  border: #ececec;
  border-style: solid;
  border-width: 0.8px;
  font-weight: 400;
  color: #212121;
}

.ellipsis-pagination .page-num:hover {
  background-color: #d3d3d3;
}

.ellipsis-pagination .page-break {
  padding: 5px 15px;
  font-weight: 600;
}

.ellipsis-pagination .active-page {
  background-color: rgb(49, 128, 207);
  color: white;
}

.continue-and-cancel-button {
  min-width: 200px;
  align-items: center;
  justify-content: center;
  margin: 0 25px;
  display: flex;
}

.stickyKeepConstant {
  position: sticky;
  left: 150px;
  background-color: rgb(89, 136, 184);
  padding-left: 10px;
  z-index: 150;
}

.fontAwesomeIconAsButton {
  padding: 7px 12px;
}

.checkbox-col {
  margin-left: 10px;
}

.my-contacts-card-buttons {
  display: flex;
  gap: 5px;
  float: right;
}

.generalInfos-th {
  border: 0;
  font-weight: normal;
}
