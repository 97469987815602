.common-card {
  box-shadow: none;

  &.with-border {
    border: 3px solid #75affa;
    box-shadow: 0 2px 4px rgba(126, 142, 177, 0.12);
  }

  .title-center {
    text-align: center;
  }

  .title-left {
    text-align: left;
  }

  .section-title {
    font-weight: bold;
  }

  .list-group {
    border: 1px solid #dee2e6;
  }

  .header-buttons,
  .footer-buttons,
  .table-buttons {
    display: flex;
    gap: 5px;
  }
}
