@each $color, $value in $theme-colors {
  .bg-#{$color}-light {
    background-color: adjust-color($value, $lightness: 7.5%);
  }
}

@each $color, $value in $theme-colors {
  .bg-#{$color}-dark {
    background-color: adjust-color($value, $lightness: -7.5%);
  }
}

.bg-brown {
  background-color: brown;
}
