.workload-table-container {
  display: flex;
  overflow: auto;
}

.tableCell {
  min-width: 150px !important;
  border: 1px solid white;
  text-align: center;
  background-color: whitesmoke;
}

.stickyCellTopLeft {
  position: sticky;
  top: 0px;
  left: 0px;
  z-index: 1;
  min-width: 200px;
  text-align: center;
  border: 1px solid white;
  background-color: rgb(180, 200, 220);
}

.stickyHeader {
  position: sticky;
  top: 0px;
  min-width: 150px !important;
  height: 35px;
  text-align: center;
  border: 1px solid white;
  background-color: rgb(180, 200, 220);
}

.stickyFirstColumn {
  position: sticky;
  left: 0px;
  min-width: 13vw !important;
  background-color: rgb(200, 220, 240);
  padding: 3px;
  padding-left: 5px;
  text-align: left;
}

.stickySecondColumn {
  position: sticky;
  left: 150px; //must be equal to width of the first column
  min-width: 100px !important;
  height: 100%;
  background-color: rgb(200, 220, 240);
  padding: 0 0.5vw;
}

.withEmployeeBorder {
  border-bottom: 1px solid rgb(180, 200, 220);
}

.withDataBorder {
  border-bottom: 1px solid white;
}

.stickyHeaderMonthOrWeek {
  position: sticky;
  top: 0px;
  min-width: 120px !important;
  text-align: center;
  border: 1px solid white;
  background-color: rgb(180, 200, 220);
}

.tableCellMonthOrWeek {
  min-width: 120px !important;
  border: 1px solid white;
  text-align: center;
  background-color: whitesmoke;
  border-radius: 5px;
}

.employeeIcon {
  height: 30px;
}

.employeeIconDailyView {
  height: 50px;
}

.maximised {
  position: absolute;
  top: 10px;
  left: 10px;
  bottom: 10px;
  right: 10px;
  z-index: 1000;
  overflow: scroll;
}

.utilizationTableData {
  background-color: #ededed;
  border: 1px solid white;
  width: auto;
  text-align: center;
  vertical-align: middle;
  padding: 10px;
}

.utilizationTableHeader {
  position: sticky;
  top: 0px;
  background-color: rgb(49, 128, 207);
  color: white;
  border: 1px solid white;
}
