.spinner {
  width: 100%;
  height: 100%;
}

.spinner > div {
  display: flex;
  justify-content: center;
  align-items: center;
  align-self: center;
  text-align: center;
  position: fixed;
  top: 50%;
  left: 50%;
}
