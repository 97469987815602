.rdtCounter .rdtCount {
  height: initial;
}
.rdtCounterSeparator {
  line-height: 105px;
}
.rdtPicker td,
.rdtPicker th {
  text-align: center;
  line-height: initial;
}

.rdtPicker tr {
  background-color: #f8f9fa;
}

.rdtPicker {
  position: relative;
}

.dropdown {
  position: relative;
  z-index: 1000;
}

.request-table .table td {
  vertical-align: top;
}

.marker-color {
  color: red;
}

.input-label {
  font-weight: bold;
}

.warning-button {
  background-color: #ffe5b2 !important;
}

.warning-text {
  font-weight: bold;
  color: #453208;
}

.danger-button {
  background-color: #fedcda !important;
}

.danger-text {
  font-weight: bold;
  color: #43130f;
}

.circle {
  display: flex;
  width: 80px;
  height: 80px;
  border-radius: 50%;
}

.stripes {
  background-image: linear-gradient(45deg, #666 25%, transparent 25%),
    linear-gradient(-45deg, #666 25%, transparent 25%),
    linear-gradient(45deg, transparent 75%, #666 75%),
    linear-gradient(-45deg, transparent 75%, #666 75%);
  background-size: 2px 2px;
  background-position: 0 0, 1px 0, 1px -1px, 0px 1px;
}

.flex-button {
  display: flex;
  gap: 5px;
}
